<template>
	<div :class="themeClass" class="width-fill bg-F8F9FE">
		<div class="min-width1100 max-width1100 margin-lr-auto box-sizing">
			<!-- 开店协议 -->
			<div class="flex-row margin-tb-20" v-if="isAgreement">
				<div class="left-box bg-fff margin-r-10">
					<div class="text-center left-title bg-F2F2F2">商城协议</div>
					<div class="left-content padding-lr-13" :class="item.checked?'bg-DDEBFF':''" v-for="(item,index) of agreementData"
					 :key="index" @click="agreementTabClick(item)">{{item.fTitle}}</div>
				</div>
				<div class="right-box padding-tb-25 padding-lr-20 bg-fff">
					<div class="font-size20 font-weight700 margin-b-20">{{fTitle}}</div>
					<div class="richtext-box margin-b-10">
						<RichTextStyle v-if="content != null" :html="content" :styleRule="rule" />
					</div>
					<div class="btn-box text-center" :class="countDown>0?'bg-666':'background-color-theme pointer'" @click="countDown>0?'':nextStepClick()">{{countDown>0?'阅读满'+countDown+'秒后可同意并继续':'同意并继续'}}</div>
				</div>
			</div>
			<!-- 开店表单 -->
			<div v-else>
				<div class="underReviewBg-img flex-row-center-center" v-if="isThereAStore">
					<div class="underReview-box bg-fff radius10 flex-row-start-center padding-20">
						<div class="flex-row-start-center">
							<div class="underReview1-img margin-r-35" v-if="shopData.fApproveStatus == 1 && shopData.fStatus == 0"></div>
							<div class="underReview254-img margin-r-35" v-if="(shopData.fApproveStatus == 254 && shopData.fStatus == 0) || (shopData.fApproveStatus == 254 && shopData.fStatus == 1)"></div>
							<div class="underReview255-img margin-r-35" v-if="shopData.fApproveStatus == 255 && shopData.fStatus == 0"></div>
							<div class="flex-colum">
								<div class="flex-colum color-theme font-size20 font-weight700" v-if="shopData.fApproveStatus == 1 && shopData.fStatus == 0">
									<div class="margin-b-15">正在预审中！</div>
									<div class="margin-b-15">我们会尽快为你处理</div>
								</div>
								<div class="flex-colum font-color-3FD09E font-size20 font-weight700" v-if="shopData.fApproveStatus == 254 && shopData.fStatus == 0">
									<div class="margin-b-15">预审通过！</div>
								</div>
								<div class="flex-colum font-color-FF0000 font-size20 font-weight700" v-if="shopData.fApproveStatus == 255 && shopData.fStatus == 0">
									<div class="margin-b-15">未通过预审！</div>
								</div>
								<div class="flex-colum font-color-3FD09E font-size20 font-weight700" v-if="shopData.fApproveStatus == 254 && shopData.fStatus == 1">
									<div class="margin-b-15">开店成功！</div>
								</div>
								<div class="font-color-666 margin-b-10">
									申请单号：{{shopData.fBillID}}
								</div>
								<div class="font-color-666 margin-b-25">
									店铺名称：{{shopData.fShopName}}
								</div>
								<!-- <div class="flex-row" v-if="shopData.fApproveStatus == 1 && shopData.fStatus == 0">
									<div class="underReview-btn text-center border-color-theme color-theme margin-r-10" v-loading.fullscreen.lock="auditLonding"
									 @click="onlineAudit">
										审核同意
									</div>
									<div class="underReview-btn text-center border-color-theme color-theme" v-loading.fullscreen.lock="auditFailedLonding"
									 @click="auditFailed">
										审核不同意
									</div>
								</div> -->
								<div class="underReview-btn text-center border-color-theme color-theme pointer" v-if="shopData.fApproveStatus == 254 && shopData.fStatus == 1"
								 @click="toLogin">
									开始营业
								</div>
								<div class="flex-row">
									<div class="underReview-btn text-center border-color-theme color-theme pointer" v-if="shopData.fApproveStatus == 254 && shopData.fStatus == 0"
									 @click="downShopSettleInAgreement" v-loading.fullscreen.lock="londing">
										下载店铺入驻协议
									</div>
									<div class="underReview-btn text-center border-color-theme color-theme pointer margin-l-13" v-if="shopData.fApproveStatus == 254 && shopData.fStatus == 0&&AccountPayWalletInfo"
									 @click="toOpenAccountPay" v-loading.fullscreen.lock="londing">
										去开通账服通钱包
									</div>
								</div>
								<div class="underReview-btn text-center border-color-theme color-theme pointer" v-if="shopData.fApproveStatus == 255 && shopData.fStatus == 0"
								 @click="formShow">
									重新申请
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="min-width1100 max-width1100 margin-tb-20 margin-lr-auto" v-else>
					<div class="margin-b-15 bg-fff padding-15">
						<div class="font-size16 margin-b-5">
							您好，{{getUserInfo.fUserName}}！欢迎在聚材通平台申请开店！
						</div>
						<div class="font-size18 font-weight700 margin-b-10" style="display: flex;align-items: center;">
							<div>注册信息</div>
							<div style="display: flex;justify-content: center;">
								<el-tooltip placement="right">
									<div slot="content">
										积分：指平台上的店铺设置的积分奖励制，买家所获积分，在店铺的积分使用规则前提下可用于抵货款、兑换金币、抽奖以及店铺开展的所有使用积分项目。积分只能在其所产生的店铺使用，不能跨店铺合并使用。<br/>
										金币：指平台设置的金币奖励制，会员所获金币为平台奖励工具，在金币使用规则前提下在金币商城可用于兑换商品以及平台开展的所有使用金币项目。<br/>
										积分产生：买家在店铺中完成提货、任务、评价、首次关注、店铺赠送等可获得积分，积分属于各店铺的奖励机制。<br/>
										积分兑换金币：积分可以兑换平台金币，但平台金币不能兑换积分，会员使用积分兑换平台金币后，不能退回。<br/>
										兑换比例：1积分=10金币。<br/>
									</div>
									<div class="form-label-mark flex-row-center-center">?</div>
								</el-tooltip>
							</div>
						</div>
						<el-row :gutter="20">
							<el-col :span="10">
								<div class="flex-row">
									<div class="font-color-666 width-100 flex-row-justify-flex-end">企业名称：</div>
									<div>{{getuserData.fCustomerName}}</div>
								</div>
							</el-col>
							<el-col :span="10">
								<div class="flex-row">
									<div class="font-color-666 width-115 flex-row-justify-flex-end">银行账号：</div>
									<div>{{getuserData.fBankAccounts}}</div>
								</div>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="10">
								<div class="flex-row">
									<div class="font-color-666 width-100 flex-row-justify-flex-end">统一信用代码：</div>
									<div>{{getuserData.fIdentityCode}}</div>
								</div>
							</el-col>
							<el-col :span="10">
								<div class="flex-row">
									<div class="font-color-666 width-115 flex-row-justify-flex-end">开户行：</div>
									<div>{{getuserData.fBankNames}}</div>
								</div>
							</el-col>
							<!-- <el-col :span="10">
								<div class="flex-row">
									<div class="font-color-666 width-115 flex-row-justify-flex-end">法人身份证号码：</div>
									<div>{{getuserData.fLegalPersonCard}}</div>
								</div>
							</el-col> -->
						</el-row>
						<!-- <el-row :gutter="20">
							<el-col :span="10">
								<div class="flex-row">
									<div class="font-color-666 width-100 flex-row-justify-flex-end">公司电话：</div>
									<div>{{getuserData.fPhone}}</div>
								</div>
							</el-col>
							<el-col :span="10">
								<div class="flex-row">
									<div class="font-color-666 width-115 flex-row-justify-flex-end">法人手机号：</div>
									<div>{{getuserData.fPhone}}</div>
								</div>
							</el-col>
						</el-row> -->
						<!-- <el-row :gutter="20">
							<el-col :span="10">
								<div class="flex-row">
									<div class="font-color-666 width-100 flex-row-justify-flex-end">开户行：</div>
									<div>{{getuserData.fBankNames}}</div>
								</div>
							</el-col>
						</el-row> -->
					</div>

					<div class="bg-fff padding-15 margin-b-10">
						<div class="form-box margin-auto">
							<div class="font-size16 margin-b-15 flex-row-center-center">
								<span class="iconfont icon-biaodan color-theme font-size25"></span>
								申请开店信息
							</div>
							<el-form ref="form" :rules="rules" :model="form" :label-width="formLabelWidth" label-position="right">
								<el-form-item label="店铺名称" prop="fShopName">
									<el-input v-model="form.fShopName" placeholder="请填写店铺名称,建议4-6个汉字" />
								</el-form-item>
								<!-- <el-form-item label="店铺类型" prop="fShopTypeID">
									<el-select v-model="form.fShopTypeID" placeholder="请选择店铺类型" style="width: 100%;" ref="fShopTypeID">
										<el-option v-for="(item,index) in shopTypeList" :key="index" :label="item.fShopTypeName" :value="item.fShopTypeID" />
									</el-select>
								</el-form-item> -->
								<!-- <el-form-item label="开户行号" prop="fBankNo">
									<el-input v-model="form.fBankNo" placeholder="请填写开户行号" />
								</el-form-item> -->
								<!-- <el-form-item label="开户行" prop="fBankOfDeposit">
									<el-input v-model="form.fBankOfDeposit" placeholder="请填写开户行" />
								</el-form-item> -->
								<!-- <el-form-item label="收款账号" prop="fCollectionAccountNo">
									<el-input v-model="form.fCollectionAccountNo" placeholder="请填写收款账号" />
								</el-form-item> -->
								<el-form-item label="保证金(万元)" prop="fDepositMoney">
									<el-input v-model="form.fDepositMoney" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请填写保证金" />
								</el-form-item>
								<el-form-item label="手续费比例(%)" prop="fFeeRadio">
									<el-input v-model="form.fFeeRadio" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请填写手续费比例" />
								</el-form-item>
								<el-form-item label="法人" prop="fLegalPerson">
									<el-input v-model="form.fLegalPerson" placeholder="请填写法人姓名" />
								</el-form-item>
								<!-- <el-form-item label="办公电话" prop="fOfficePhone">
									<el-input v-model="form.fOfficePhone" placeholder="请填写办公电话" />
								</el-form-item> -->
								<!-- <el-form-item label="客服电话" prop="fcsPhone">
									<el-input v-model="form.fcsPhone" placeholder="请填写客服电话" />
								</el-form-item> -->
								<!-- <el-form-item label="质量服务电话" prop="fqsPhone">
									<el-input v-model="form.fqsPhone" placeholder="请填写质量服务电话" />
								</el-form-item> -->
								<!-- <el-form-item label="投诉电话" prop="fComplaintsPhone">
									<el-input v-model="form.fComplaintsPhone" placeholder="请填写投诉电话" />
								</el-form-item> -->
								<el-form-item label="联系人" prop="fLiaisonMan">
									<el-input v-model="form.fLiaisonMan" placeholder="请填写联系人姓名" />
								</el-form-item>
								<el-form-item label="联系电话" prop="fOfficePhone">
									<el-input v-model="form.fOfficePhone" maxlength="11" oninput="value=value.replace(/[^0-9-]/g,'')" placeholder="请填写联系电话" />
								</el-form-item>
								<el-form-item label="邮箱" prop="feMail">
									<el-input v-model="form.feMail" placeholder="请填写邮箱" />
								</el-form-item>
								<el-form-item  label="经营范围(多选)"  prop='fCapTypeIDList'>
									<el-select multiple  v-model="form.fCapTypeIDList"
									 style="width: 100%" @change="changefCapTypeIDList">
										<el-option v-for="(item, index) in fCapTypeOptions"
										     :key="item.fGoodsClassID"
											 :label="item.fGoodsClass"
											:value="item.fGoodsClassID">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="开通积分活动" >
								<el-col >
								  <div style="display: flex;margin-top: 10px;">
									<el-tooltip placement="right">
										<div slot="content">
											积分指平台上的卖家（店铺）设置的积分奖励制，买家所获积分，在卖家（店铺）的积分使用规则前提下可用于抵水泥货款、<br>
											兑换金币、抽奖以及卖家（店铺）开展的所有使用积分项目。积分只能在其所产生的卖家（店铺）使用，不能跨店铺合并使用。
										</div>
										<div class="form-label-mark1 flex-row-center-center" style="margin-top: 3px;">?</div>
									</el-tooltip>
									<el-switch v-model="form.fIsEnablePoint" :active-value="1" :inactive-value="0" @change="changefIsEnablePoint"></el-switch>
								</div>
								</el-col >	
								</el-form-item>
								<el-form-item label="参与积分换金币">
									<el-col >
									  <div style="display: flex;margin-top: 10px;">
										<el-tooltip placement="right">
											<div slot="content">
												金币指平台设置的金币奖励制，会员所获金币为平台奖励工具，在金币使用<br>
												规则前提下在金币商城可用于兑换商品以及平台开展的所有使用金币项目。
											</div>
											<div class="form-label-mark1 flex-row-center-center" style="margin-top: 3px;">?</div>
										</el-tooltip>
											<el-switch v-model="form.fIsEnablePointToGold" :active-value="1" :inactive-value="0" @change="changefIsEnablePointToGold"></el-switch>
									</div>
									</el-col >	
								</el-form-item>
								<el-form-item label="备注">
									<el-input v-model="form.fRemark" placeholder="请填写备注" />
								</el-form-item>
								<el-form-item label="附件">
									<div class="border-color-theme color-theme btn-box1 text-center" @click="dialogVisible = true">
										+ 添加附件
									</div>
								</el-form-item>

								<!-- <el-form-item label="授权委托书" prop="fRemark">
								<div class="padding-15 border-F2F2F2">
									<UploadImgList uploadDir="Web/Platform" v-model="annexEntityArray1" uploadType="image" />
								</div>
							</el-form-item> -->
								<el-form-item>
									<div class="flex-row">
										<div class="color-theme border-color-theme submitBtn-box margin-r-10 text-center" v-loading.fullscreen.lock="submitAuditLoading"
										 @click="submitAuditForm">提交审核</div>
										<div class="background-color-theme submitBtn-box text-center" v-loading.fullscreen.lock="submitLoading"
										 @click="submitForm">暂存</div>
										<!--  v-if="submitShow" -->
										<!-- <div class="background-color-theme submitBtn-box" v-else @click="updateForm">暂存</div> -->
									</div>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog  v-if="dialogVisible" :visible.sync="dialogVisible" width="60%" v-dialogDrag
		 :close-on-press-escape="false" :close-on-click-modal="false">
			<div ref="dialogBox" class="dialogScroll">
				<div style="margin-bottom:15px">
					<span style="font-size: 20px;">附件区</span>
					<span class="font-color-FF9900 font-size12"  style="margin-left: 20px;">*提示：仅支持上传{{uploadRule[0].rule.fFileType}}格式文件；</span>
				</div>
				<el-form ref="formData" label-width="110px" label-position="left">
					<el-row :gutter="20">
						<el-col :span="12" v-for="(item,index) of uploadRule" :key="index" style="position: relative;height: 194px;">
							<el-form-item :label="item.rule.fRuleName" :class="item.rule.fIsNecessary==0 ? '': 'isNecessary'">
								<div class="padding-15 border-F2F2F2">
									<UploadImgList :accept="item.rule.fFileType" :rules="item.rule" :uploadToken="uploadToken[index]" :limit="1"
									 v-model="annexEntityArray[index]" uploadType="image" />
								</div>
								<div v-if="item.rule.fRuleName== '店铺logo' ||  item.rule.fRuleName=='公司电子收据专用章'"  class="font-color-FF9900 font-size12">*提示：仅支持上传{{item.rule.fFileType}}格式文件；</div>
							    <div v-if="item.rule.fRuleName== '增值税一般纳税人'"  class="font-color-FF9900 font-size12">*提示：所在省市网上电子税务局查询；</div>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="enclosure">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import UploadImgList from "@/components/upload/UploadImgList.vue";
	import RichTextStyle from "@/components/richtext/RichTextStyle";
	export default {
		components: {
			UploadImgList,
			RichTextStyle
		},
		data() {
			const validateDepositMoney = (rule, value, callback) => {
				if (!value) {
					callback(new Error('保证金不能为空'))
				} else if (value.toString().indexOf(".") != -1 && value.toString().split('.').length > 2) {
					callback(new Error('请输入正确格式的保证金')) //防止输入多个小数点
				}
				//  else if (value.indexOf(".") != -1 && value.split('.')[1].length > 2) {
				// 	callback(new Error('请输入正确的小数位数')) //小数点后两位
				// }
				else {
					callback();
				}
			};
			const validateFeeRadio = (rule, value, callback) => {
				console.log("value.toString().split('.').length > 2", value.toString().split('.').length > 2);
				if (!value) {
					callback(new Error('手续费比率不能为空'))
				} else if (value.toString().indexOf(".") != -1 && value.toString().split('.').length > 2) {
					callback(new Error('请输入正确格式的手续费比率')) //防止输入多个小数点
				}
				//  else if (value.indexOf(".") != -1 && value.split('.')[1].length > 2) {
				// 	callback(new Error('请输入正确的小数位数')) //小数点后两位
				// }
				else {
					callback();
				}
			};
			return {
				fCapTypeOptions: [],
				countDown: 10,
				auditLonding: false,
				auditFailedLonding: false,
				submitAuditLoading: false,
				submitLoading: false,
				londing: false,
				isAgreement: true, //是否显示协议
				agreementData: [], //店铺协议
				fTitle: '', //富文本标题
				content: '', //富文本内容
				rule: [], //富文本规则

				isThereAStore: false, //当前用户是否开店
				shopData: {}, //已经开店的店铺信息

				submitShow: false, //当前用户是否开店，开店之后是否初始

				uploadRule: [], //上传图片规则
				uploadToken: [], //上传图片token
				limitTypeArr: '', //上传图片格式规则
				form: {
					fCapTypeIDList:[],
					fBillID: '',
					fShopName: '', //店铺名称
					fShopTypeID: '', //店铺类型id
					fAgreementHistID: '', //协议id
					fBankNo: '', //开户行号
					fBankOfDeposit: '', //开户行
					fCollectionAccountNo: '', //收款账号
					fDepositMoney: '', //保证金
					fFeeRadio: '', //手续费比率
					fOfficePhone: '', //办公电话
					feMail: '', //邮箱
					fcsPhone: '', //客户电话
					fqsPhone: '', //服务质量电话
					fComplaintsPhone: '', //投诉电话
					fIsEnablePoint: 1,
					fIsEnablePointToGold: 1,
					fRemark: '', //备注
					fLegalPerson: "", //法人
					fLiaisonMan: "", //联系人
				},
				rules: {
					fShopName: [{
						required: true,
						message: '店铺名称不能为空',
						trigger: 'blur'
					}],
					fShopTypeID: [{
						required: true,
						message: '请选择店铺类型',
						trigger: 'change'
					}],
					fBankNo: [{
						required: true,
						message: '开户行号不能为空',
						trigger: 'blur'
					}],
					fBankOfDeposit: [{
						required: true,
						message: '开户行不能为空',
						trigger: 'blur'
					}],
					fCollectionAccountNo: [{
						required: true,
						message: '收款账号不能为空',
						trigger: 'blur'
					}],
					// fDepositMoney: [{
					// 	required: true,
					// 	message: '保证金不能为空',
					// 	trigger: 'blur'
					// }],
					fDepositMoney: [{
						required: true,
						trigger: 'blur',
						validator: validateDepositMoney
					}],
					// fFeeRadio: [{
					// 	required: true,
					// 	message: '手续费比率不能为空',
					// 	trigger: 'blur'
					// }],
					fFeeRadio: [{
						required: true,
						trigger: 'blur',
						validator: validateFeeRadio
					}],
					fLiaisonMan: [{
						required: true,
						message: '联系人姓名不能为空',
						trigger: 'blur'
					}],
					fOfficePhone: [{
						required: true,
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						message: '请输入正确的联系电话',
						trigger: 'blur'
					}],
					fLegalPerson: [{
						required: true,
						message: '法人姓名不能为空',
						trigger: 'blur'
					}],
					feMail: [{
						required: true,
						message: '邮箱不能为空',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
						message: '请输入正确邮箱',
						trigger: 'blur'
					}],
					fcsPhone: [{
						required: true,
						message: '客户电话不能为空',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						message: '请输入正确的手机号码',
						trigger: 'blur'
					}],
					fqsPhone: [{
						required: true,
						message: '服务质量不能为空',
						trigger: 'blur'
					}],
					fComplaintsPhone: [{
						required: true,
						message: '投诉电话不能为空',
						trigger: 'blur'
					}],
					fCapTypeIDList:[{
						required: true,
						message: '经营范围不能为空',
						trigger: 'blur'
					}]
					// annexEntityArray: [{
					// 	required: true,
					// 	message: '当前图片不能为空',
					// 	trigger: 'change'
					// }],
				},
				formLabelWidth: '120px', //label宽

				shopTypeList: [], //店铺类型弹窗数据

				annexEntityArray: [],
				fIsNecessaryData: [],
				pictureIsNecessaryDataName: [],
				getuserData: {
					// fBankNames:""
				},
				dialogVisible: false, //新增编辑弹窗
				promiseTimer: '',
				AccountPayWalletInfo: false,
			}
		},
		computed: {
			...mapGetters(['getThemeName', 'getUserInfo']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		mounted() {
			this.getfCapType()
			this.getShopApplication();
			this.getAgreementHistory();
			this.getcurrentUser();
			this.getAccountPayWalletInfo();
		},
		methods: {
			//开通积分活动
			changefIsEnablePoint(value){
				this.form.fIsEnablePoint=value;
				if(this.form.fIsEnablePoint == 0){
					this.form.fIsEnablePointToGold =0
				}
				
			},
			//参与积分换金币
			changefIsEnablePointToGold(val){
				this.form.fIsEnablePointToGold=val;
				console.log(this.form.fIsEnablePointToGold)
				 if(this.form.fIsEnablePointToGold == 1){
					this.form.fIsEnablePoint = 1
				}
			},
			//获取经营范围
			getfCapType() {
				this.ApiRequestPost(
					"/api/mall/ebbase/shop-type-goodsClass-ref/get-list-not-self-support",
				).then(
					(res) => {
						console.log(res,'999999999999999您你你')
						for(let item of res.obj){
							item.fGoodsClassID = Number(item.fGoodsClassID)
						}
						this.fCapTypeOptions = res.obj
					},
					(error) => {}
				);
			},
			changefCapTypeIDList(e){
				this.$forceUpdate()
			},
			//确认附件
			enclosure() {
				let message = [];
				if (this.fIsNecessaryData.length > 0) {
					this.fIsNecessaryData.forEach(item => {
						if (this.annexEntityArray[item.index].length < 1) {
							let text = item.fRuleName + '不能为空';
							message.push(text)
							return
						}
					})
				}
				if (message.length > 0) {
					this.$message.info(message[0])
				} else {
					this.dialogVisible = false;
				}
			},
			//   获取账服通钱包余额
			getAccountPayWalletInfo() {
				this.ApiRequestPost(
					"api/mall/ebbalance/customer-acs/get-my-ACS"
				).then(
					(result) => {
						if (result.obj == null || result.obj.fCustomerID == null) {
							this.AccountPayWalletInfo = true;
							return;
						}
					},
					(rej) => {}
				);
			},
			//查询店铺协议接口
			downShopSettleInAgreement() {
				this.londing = true;
				this.ApiRequestPost('api/mall/ebcustomer/baseinfo/down-shop-settle-in-agreement').then(res => {
					window.open(res.obj, '_blank')
					this.londing = false;
				}, error => {

				});
			},
			//跳转正式库管理端登录页面
			toLogin() {
				window.open('https://www.xjjcjc.com:9528/#/login', '_black');
			},
			toOpenAccountPay() {
				this.$router.replace({
					path: '/businessme/AccountPay',
				});
			},
			aaa() {
				this.ApiRequestPost('api/mall/ebbalance/customer-acs/get-my-ACS').then(res => {
					if (res.obj == null) {
						this.$confirm('尚未开通账服通，请前往开通后再来开店', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$router.push({
								path: 'businessme/AccountPay'
							});
						}).catch(() => {

						});
					} else {
						this.$router.push('/OpenShop/OpenShopForm')
					}
				}, error => {

				});
			},

			//获取当前登陆人信息
			getcurrentUser() {
				this.ApiRequestPost('api/mall/ebcustomer/baseinfo/get-by-current-user-customer-base-info-full', {})
					.then(
						res => {
							this.getuserData = res.obj;
							console.log(this.getuserData, "this.getuserData")
						}, error => {}
					)
			},
			//获取当前登录人有没有店铺
			getShopApplication() {
				this.ApiRequestPost('api/mall/ebshop/create-requestbill/get-shop-application', {})
					.then(
						res => {
							console.log('获取当前登录人有没有店铺', res);
							if (res.obj && res.obj.fShopTypeID == 2) {
								this.isAgreement = false;
								this.shopData = res.obj;
								res.obj.fDepositMoney = res.obj.fDepositMoney == 0 ? '' : res.obj.fDepositMoney;
								res.obj.fFeeRadio = res.obj.fFeeRadio == 0 ? '' : res.obj.fFeeRadio;
								var middledata = []
								for(let item of res.obj.goodsClass){
									middledata.push(item.fGoodsClassID)
								}
								this.form = res.obj;
								this.form.fCapTypeIDList = middledata
								this.annexEntityArray = [];
								let Type = [];
								this.uploadRule = res.obj.annexTypeRule;
								for (let i = 0; i < this.uploadRule.length; i++) {
									if (this.uploadRule[i].rule.fIsNecessary == 1) {
										this.fIsNecessaryData.push({
											fRuleID: this.uploadRule[i].rule.fAnnexTypeID,
											fRuleName: this.uploadRule[i].rule.fRuleName,
											index: i
										})
									}
								}
								res.obj.annexTypeRule.forEach((item, index) => {
									item.rule.fFileType = item.rule.fFileType.split(',');
									for (let i = 0; i < item.rule.fFileType.length; i++) {
										item.rule.fFileType[i] = '.' + item.rule.fFileType[i]
									}
									item.rule.fFileType = item.rule.fFileType.toString();
									// Type = item.rule.fFileType.split(',');
									this.uploadToken.push({token:item.token});
									if (res.obj.annexList.some(p => p.fRuleID == item.rule.fRuleID)) {
										var annexe = res.obj.annexList.find(p => p.fRuleID == item.rule.fRuleID);
										let tempObj = {};
										tempObj.fPath = annexe.fPath;
										tempObj.ruleId = item.rule.fRuleID;
										tempObj.fAnnexTypeID = annexe.fAnnexTypeID;
										tempObj.fFileExName = annexe.fFileExName;
										tempObj.fSourceFileName = annexe.fSourceFileName;
										this.annexEntityArrayBase = [];
										this.annexEntityArrayBase.push(tempObj);
										this.annexEntityArray[index] = this.annexEntityArrayBase;
									} else {
										this.annexEntityArray[index] = [];
									}
								});

								// for (let i = 0; i < Type.length; i++) {
								// 	Type[i] = '.' + Type[i]
								// }
								// console.log('Type', Type);
								// this.limitTypeArr = Type.toString();
								console.log('this.annexEntityArray1', this.annexEntityArray);
								console.log('上传图片token', this.uploadToken);
								if (res.obj.fApproveStatus == 0) {
									this.isThereAStore = false;
									this.submitShow = false;
								} else {
									this.isThereAStore = true;
								}
							} else {
								this.isAgreement = true;
								let _this = this;
								this.promiseTimer = setInterval(function() {
									_this.countDown = _this.showtime()
								}, 1000);
								this.isThereAStore = false;
								this.submitShow = true;
								// this.getShopTypeList();
								this.getAgreementHistory();
								this.getOpenShopAnnexRule();
							}
						},
						error => {}
					);
			},
			showtime() {
				let second = 10;
				if (this.countDown > 0) {
					this.countDown -= 1;
				} else if (this.countDown == 0) {
					clearInterval(this.promiseTimer)
				}
				return this.countDown; //返回倒计时的字符串
			},
			//获取协议内容
			getAgreementHistory() {
				this.ApiRequestPost('api/mall/ebbase/agreement-history/get-by-open-shop-list', {})
					.then(
						res => {
							console.log('获取协议id', res);
							res.obj.items.forEach((item, index) => {
								if (index == 0) {
									res.obj.items[index].checked = true;
									this.fTitle = res.obj.items[index].fTitle;
									this.content = res.obj.items[index].fContent;
									this.form.fAgreementHistID = res.obj.items[index].fAgreementHistID;
								} else {
									res.obj.items[index].checked = false;
								}
							});
							this.agreementData = res.obj.items;
						},
						error => {}
					);
			},
			//切换协议
			agreementTabClick(obj) {
				this.agreementData.forEach((item, index) => {
					if (obj.fAgreementHistID == item.fAgreementHistID) {
						this.form.fAgreementHistID = obj.fAgreementHistID;
						this.agreementData[index].checked == true;
					} else {
						this.agreementData[index].checked == false;
					}
				});
			},
			//协议同意并继续
			nextStepClick() {
				console.log('4556445645');
				this.isAgreement = false;
			},
			//审核不通过
			formShow() {
				// this.getOpenShopAnnexRule();
				this.isThereAStore = false;
			},
			//获取附件验证规则
			getOpenShopAnnexRule() {
				this.ApiRequestPost('api/mall/ebshop/create-requestbill/get-create-annex-bill-type-rule', {
						fShopTypeID: 2
					})
					.then(
						res => {
							this.uploadRule = res.obj.annexTypeRule;
							console.log('获取附件验证规则', this.uploadRule);
							let Type = [];
							for (let i = 0; i < this.uploadRule.length; i++) {
								if (this.uploadRule[i].rule.fIsNecessary == 1) {
									this.fIsNecessaryData.push({
										fRuleID: this.uploadRule[i].rule.fAnnexTypeID,
										fRuleName: this.uploadRule[i].rule.fRuleName,
										index: i
									})
								}
							}
							for (let item of res.obj.annexTypeRule) {
								item.rule.fFileType = item.rule.fFileType.split(',');
								for (let i = 0; i < item.rule.fFileType.length; i++) {
									item.rule.fFileType[i] = '.' + item.rule.fFileType[i]
								}
								item.rule.fFileType = item.rule.fFileType.toString();
								// Type = item.rule.fFileType.split(',');
								this.uploadToken.push({token:item.token});
							}
							// for (let i = 0; i < Type.length; i++) {
							// 	Type[i] = '.' + Type[i]
							// }
							console.log('Type', Type);

							// this.limitTypeArr = Type.toString();
							res.obj.annexTypeRule.forEach((item, index) => {
								if (res.obj.annexList.some(p => p.fRuleID == item.rule.fRuleID)) {
									var annexe = res.obj.annexList.find(p => p.fRuleID == item.rule.fRuleID);
									let tempObj = {};
									tempObj.fPath = annexe.fPath;
									tempObj.ruleId = item.rule.fRuleID;
									tempObj.fAnnexTypeID = annexe.fAnnexTypeID;
									tempObj.fFileExName = annexe.fFileExName;
									tempObj.fSourceFileName = annexe.fSourceFileName;
									this.annexEntityArrayBase = [];
									this.annexEntityArrayBase.push(tempObj);
									this.annexEntityArray[index] = this.annexEntityArrayBase;
								} else {
									this.annexEntityArray[index] = [];
								}
							})

							// this.getShopApplication();
							console.log('this.limitTypeArr', this.limitTypeArr);
							console.log('上传图片规则', this.uploadRule);
							console.log('上传图片token', this.uploadToken);
							console.log('上传图片格式规则', this.limitTypeArr);
							console.log('this.annexEntityArray111', this.annexEntityArray);
						},
						error => {}
					);
			},
			//获取店铺类型弹窗数据
			getShopTypeList() {
				this.ApiRequestPostNOMess('api/mall/ebbase/shop-type/get-onstatus-list', {})
					.then(
						res => {
							console.log('获取附件验证规则', res);
							this.shopTypeList = [];
							for (let item of res.obj.items) {
								if (item.fShopTypeID != -1) {
									this.shopTypeList.push(item);
								}
							}
						},
						error => {}
					);
			},
			//保存
			submitForm() {
				this.createRequestbill();
			},
			//创建申请
			createRequestbill() {
				this.submitLoading = true;
				console.log('this.annexEntityArray', this.annexEntityArray);
				let annexList = [];
				let isNecessaryArry = [];
				this.annexEntityArray.forEach((item, index) => {
					console.log('item2345', item);
					item.forEach((childitem, childindex) => {
						childitem.fRuleID = childitem.ruleId;
						annexList.push(childitem);
					});
				})
				console.log('annexList', annexList);
				
				let data = this.form;
				var goodsIdList = []
				for(let item of this.form.fCapTypeIDList){
					goodsIdList.push({
						fGoodsClassID:item
					})
				}
				let params = {
					fBillID: data.fBillID,
					fDepositMoney: data.fDepositMoney,
					fFeeRadio: data.fFeeRadio,
					fAgreementHistID: data.fAgreementHistID,
					fShopName: data.fShopName,
					fOfficePhone: data.fOfficePhone,
					feMail: data.feMail,
					fCollectionAccountNo: data.fCollectionAccountNo,
					fBankNo: data.fBankNo,
					fBankOfDeposit: data.fBankOfDeposit,
					fIsEnablePoint: data.fIsEnablePoint,
					fIsEnablePointToGold: data.fIsEnablePointToGold,
					fRemark: data.fRemark,
					fLiaisonMan: data.fLiaisonMan,
					fLegalPerson: data.fLegalPerson,
					goodsClass:goodsIdList,
					// fSettleTypeID: data.fSettleTypeID,//业务类型id正式上班确定
					annexList: []
				}
				params.annexList = annexList;
				this.ApiRequestPost('api/mall/ebshop/create-requestbill/create',
						params)
					.then(
						res => {
							this.getShopApplication()
							this.submitLoading = false;
						},
						error => {
							this.submitLoading = false;
						}
					);
			},

			//编辑保存
			updateForm() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.updateRequestbill();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//编辑保存接口
			updateRequestbill() {
				this.submitLoading = true;
				let annexList = [];
				this.annexEntityArray.forEach((item, index) => {
					console.log('item', item);
					item.forEach((childitem, childindex) => {
						childitem.fRuleID = childitem.ruleId;
						annexList.push(childitem);
					});
				})
				console.log('annexList', annexList);
				let data = this.form;
				let params = {
					fStatus: data.fStatus,
					fApproveStatus: data.fApproveStatus,
					fBillID: data.fBillID,
					fShopUnitID: data.fShopUnitID,
					fBillNumber: data.fBillNumber,
					fShopCode: data.fShopCode,
					frv: data.frv,
					rowStatus: data.rowStatus,
					fDepositMoney: data.fDepositMoney,
					fFeeRadio: data.fFeeRadio,
					fAgreementHistID: data.fAgreementHistID,
					fShopTypeID: data.fShopTypeID,
					fShopName: data.fShopName,
					fOfficePhone: data.fOfficePhone,
					feMail: data.feMail,
					fcsPhone: data.fcsPhone,
					fqsPhone: data.fqsPhone,
					fComplaintsPhone: data.fComplaintsPhone,
					fCollectionAccountNo: data.fCollectionAccountNo,
					fBankNo: data.fBankNo,
					fBankOfDeposit: data.fBankOfDeposit,
					fRemark: data.fRemark,
					fSettleTypeID: data.fSettleTypeID, //业务类型id正式上班确定
					fLiaisonMan: data.fLiaisonMan,
					fLegalPerson: data.fLegalPerson,
					annexList: [],
				}
				params.annexList = annexList;
				this.ApiRequestPut('api/mall/ebshop/create-requestbill/update', params)
					.then(
						res => {
							this.submitLoading = false;
							this.getShopApplication();
						},
						error => {
							this.submitLoading = false;
						}
					);
			},

			//提交审核
			submitAuditForm() {
				console.log('this.annexEntityArray', this.annexEntityArray);
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.submitAudit();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			//提交审核接口
			submitAudit() {
				let message = [];
				if (this.fIsNecessaryData.length > 0) {
					this.fIsNecessaryData.forEach(item => {
						if (this.annexEntityArray[item.index].length < 1) {
							let text = '附件中' + item.fRuleName + '不能为空';
							message.push(text)
							return
						}
					})
				}
				if (message.length > 0) {
					this.$message.info(message[0])
					return
				}
				this.submitAuditLoading = true;
				let annexList = [];
				this.annexEntityArray.forEach((item, index) => {
					console.log('item', item);
					item.forEach((childitem, childindex) => {
						childitem.fRuleID = childitem.ruleId;
						annexList.push(childitem);
					});
				})
				let data = this.form;
				var goodsIdList = []
				for(let item of this.form.fCapTypeIDList){
					goodsIdList.push({
						fGoodsClassID:item
					})
				}
				let params = {
					fBillID: data.fBillID,
					fDepositMoney: data.fDepositMoney,
					fFeeRadio: data.fFeeRadio,
					fAgreementHistID: data.fAgreementHistID,
					fShopName: data.fShopName,
					fOfficePhone: data.fOfficePhone,
					feMail: data.feMail,
					fCollectionAccountNo: data.fCollectionAccountNo,
					fBankNo: data.fBankNo,
					fBankOfDeposit: data.fBankOfDeposit,
					fIsEnablePoint: data.fIsEnablePoint,
					fIsEnablePointToGold: data.fIsEnablePointToGold,
					fRemark: data.fRemark,
					fLiaisonMan: data.fLiaisonMan,
					fLegalPerson: data.fLegalPerson,
					goodsClass:goodsIdList,
					// fSettleTypeID: data.fSettleTypeID,//业务类型id正式上班确定
					annexList: []
				}
				params.annexList = annexList;
				console.log('annexList', annexList);
				// if (annexList.length < this.uploadRule.length) {
				// 	this.$message('请上传所有附件！');
				// 	return;
				// }
				this.ApiRequestPut('api/mall/ebshop/create-requestbill/submit-audit', params)
					.then(
						res => {
							this.submitAuditLoading = false;
							this.getShopApplication();
						},
						error => {
							this.submitAuditLoading = false;
						}
					);
			},

			//审核
			onlineAudit() {
				this.auditLonding = true;
				this.ApiRequestPut('api/mall/ebshop/create-requestbill/online-aduit', {
						id: this.shopData.fBillID
					})
					.then(
						res => {
							this.auditLonding = false;
							this.getShopApplication();
						},
						error => {}
					);
			},
			//审核不同意
			auditFailed() {
				this.auditFailedLonding = true;
				this.ApiRequestPut('api/mall/ebshop/create-requestbill/audit-failed', {
						id: this.shopData.fBillID
					})
					.then(
						res => {
							this.auditFailedLonding = false;
							this.getShopApplication();
						},
						error => {}
					);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	.left-box {
		height: 748px;
		width: 170px;
	}

	.left-title {
		height: 36px;
		line-height: 36px;
	}

	.left-content {
		height: 41px;
		line-height: 41px;
	}

	.right-box {
		width: calc(1100px - 180px);
	}

	.richtext-box {
		min-height: 575px;
		max-height: 575px;
		overflow-y: auto;
	}

	.btn-box {
		width: 235px;
		height: 45px;
		line-height: 45px;
	}

	.bg-DDEBFF {
		background-color: #DDEBFF;
	}

	.el-row {
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.width-100 {
		width: 100px;
	}

	.width-115 {
		width: 115px;
	}

	.form-box {
		width: 435px;
	}

	.underReviewBg-img {
		width: 100%;
		height: 550px;
		// background-image: url(../../../assets/imgs/HomePage/OpenShopUnderReviewBg.png);
		background-size: 100% 100%;
	}

	.underReview-box {
		width: 860px;
		height: 213px;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
	}

	.underReview1-img {
		width: 258px;
		height: 214px;
		background-image: url(../../../assets/imgs/HomePage/OpenShop1.png);
		background-size: 100% 100%;
	}

	.underReview254-img {
		width: 258px;
		height: 214px;
		background-image: url(../../../assets/imgs/HomePage/OpenShop254.png);
		background-size: 100% 100%;
	}

	.underReview255-img {
		width: 258px;
		height: 214px;
		background-image: url(../../../assets/imgs/HomePage/OpenShop255.png);
		background-size: 100% 100%;
	}

	.underReview-btn {
		width: 160px;
		height: 40px;
		line-height: 40px;
	}

	.submitBtn-box {
		width: 160px;
		height: 40px;
		line-height: 40px;
	}

	.btn-box1 {
		width: 160px;
		height: 40px;
		line-height: 40px;
	}

	.isNecessary:before {
		content: "*";
		position: absolute;
		color: red;
		top: 12px;
		left: 2px;
	}
	/deep/.el-dialog__body{
		padding:  0px 20px 30px ;
	}
	.form-label-mark {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: 1px solid #ccc;
		font-size: 12px;
		color: #ccc;
		cursor: pointer;
		margin-left: 10px;
		margin-right: 10px;
	}
	.form-label-mark1 {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: 1px solid #ccc;
		font-size: 12px;
		color: #ccc;
		cursor: pointer;
		margin-right: 10px;
	}
</style>
